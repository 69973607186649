export function scrolEvents(controller , scene){
    scene.push(
        new ScrollMagic.Scene({
            triggerElement: 'body',
            offset: window.innerHeight * 1
        })
        //.addIndicators()
        .setClassToggle('#site-header','active').addTo(controller)
    )
	
    scene.push(
        new ScrollMagic.Scene({
            triggerElement: 'body',
            offset: window.innerHeight * 1.5
        })
        //.addIndicators()
        .setClassToggle('#pagetop','active').addTo(controller)
    )
	
	const scroll_anime_name = 'scroll-anime';
	const scroll_anime_elem = document.getElementsByClassName(scroll_anime_name);
	if(scroll_anime_elem.length > 0){
		for(let i = 0; i < scroll_anime_elem.length; i++){
			let scroll_node = scroll_anime_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_node,
					offset: -300
				})
				//.addIndicators()
				.reverse(false)
				.setClassToggle(scroll_node,'_active').addTo(controller)
			)
		}
	}
    
	const scroll_custum_name = 'scroll-custum';
	const scroll_custum_elem = document.getElementsByClassName(scroll_custum_name);
	if(scroll_custum_elem.length > 0){
		for(let i = 0; i < scroll_custum_elem.length; i++){
			let custum_node = scroll_custum_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: custum_node,
					offset: -300
				})
				//.addIndicators()
				.reverse(false)
				.setClassToggle(custum_node,'_active').addTo(controller)
			)
		}
	}
	
	const scroll_img_name = 'scroll-img';
	const scroll_img_elem = document.getElementsByClassName(scroll_img_name);
	if(scroll_img_elem.length > 0){
		for(let i = 0; i < scroll_img_elem.length; i++){
			let scroll_img_node = scroll_img_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_img_node,
					offset: -300
				})
				//.addIndicators()
				.reverse(false)
				.setClassToggle(scroll_img_node,'__active').addTo(controller)
			)
		}
	}
    
    //ORIGINAL
	const scroll_bg_name = 'scroll-bg';
	const scroll_bg_elem = document.getElementsByClassName(scroll_bg_name);
	if(scroll_bg_elem.length > 0){
		for(let i = 0; i < scroll_bg_elem.length; i++){
			let bg_node = scroll_bg_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: bg_node,
					offset: 0,
                    duration: '90%'
				})
				//.addIndicators()
				.reverse(true)
				.setClassToggle(bg_node,'_change').addTo(controller)
			)
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: bg_node,
					offset: 0,
				})
				//addIndicators()
				.reverse(true)
				.setClassToggle(bg_node,'_active').addTo(controller)
			)
		}
    }
    
	const scroll_pins_name = 'pins';
	const scroll_pins_elem = document.getElementsByClassName(scroll_pins_name);
	if(scroll_pins_elem.length > 0){
		for(let i = 0; i < scroll_pins_elem.length; i++){
			let pins_node = scroll_pins_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: pins_node,
					offset: 0,
                    duration: '80%'
				})
                .setPin(pins_node)
				//.addIndicators()
				.setClassToggle(pins_node,'_change').addTo(controller)
			)
		}
    }

    // RELLAX
    if(document.getElementsByClassName('rellax').length > 0){
        const rellax = new Rellax('.rellax',{
            center:true
        });
    }
    if(document.getElementsByClassName('rellax-no').length > 0){
        const rellax_no = new Rellax('.rellax-no',{
            center:false
        });
    }


    const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
      offset: 0
    });
	
}