import {scrolEvents} from './default';
import {loading} from './loading';
import {vues} from './vues';

document.addEventListener('DOMContentLoaded', function() {

    if(navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge/)) {
        document.body.onmousewheel = function(){
            event.preventDefault();
            let wd = event.wheelDelta;
            let csp = window.pageYOffset;
            let c = csp - wd;
            window.scrollTo(0, c);
        }
    }
    
	let scene = [];
	let controller;
    vues();
    controller =  new ScrollMagic.Controller();
	scrolEvents(controller , scene);
    loading();
    

});